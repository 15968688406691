.async-hide {
  opacity: 0 !important;
}
/* Start date picker */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  font-family: "Neufile Grotesk Regular" !important;
}

.react-datepicker__navigation {
  top: 6px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--chakra-colors-cerise-500) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--chakra-colors-cerise-500) !important;
}

.react-datepicker__day-name {
  color: var(--chakra-colors-turquoise-800) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--chakra-colors-cerise-500) !important;
}

.react-datepicker__triangle {
  left: -50px !important;
}
/* End date picker */

/* Start phone input*/
.react-tel-input .form-control.invalid-number {
  border-color: #ff4f18;
  box-shadow: 0 1px 0 0px #ff4f18;
}
/* End phone input*/

.auto-complete-input-group .chakra-wrap {
  width: 100%;
}

#kustomer-ui-sdk-iframe {
  right: 0 !important;
}
@media only screen and (min-width: 62em) {
  #kustomer-ui-sdk-iframe {
    bottom: 80px !important;
  }
}

.chakra-carousel__slide {
  min-width: 100% !important;
}
